import Swiper from "swiper";
import { Navigation } from 'swiper/modules';
document.addEventListener('DOMContentLoaded', function () {
  // -----------------------------------------------------------------
  // folders slider
  class FoldersSlider extends HTMLElement {
    constructor() {
      super();
    }
    connectedCallback() {
      this.swiper = null;
      this.slider = this.querySelector('.js-slider__swiper');
      this.prev = this.querySelector('.js-slider__prev');
      this.next = this.querySelector('.js-slider__next');
      this.slides = this.querySelectorAll('.swiper-slide');

      // launch stuff
      this.initSwiper();
    }
    disconnectedCallback() {
      // console.log('folders-slider is unmounted')
      if (this.swiper.destroy) this.swiper.destroy(true, true);
    }
    initSwiper() {
      this.swiper = new Swiper(this.slider, {
        modules: [Navigation],
        speed: 300,
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 16,
        loop: true,
        breakpoints: {
          1156: {
            spaceBetween: 32
          }
        },
        navigation: {
          prevEl: this.prev ?? null,
          nextEl: this.next ?? null
        }
      });
      // hide nav if not enough slides
      if (this.slides.length <= 3) {
        this.classList.add('is-no-slide');
      }
    }
  }
  customElements.define('folders-slider', FoldersSlider);
});