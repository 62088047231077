document.addEventListener('DOMContentLoaded', function () {
  // -----------------------------------------------------------------
  // copy the list of ingredients
  class CopyList extends HTMLElement {
    constructor() {
      super();
    }
    connectedCallback() {
      // elements
      this.target = this.querySelector('.js-copy__target');
      this.button = this.querySelector('.js-copy__button');
      this.message = this.getAttribute('data-confirmation');

      // binding handler
      this.bindedHandleCopy = this.handleCopy.bind(this);

      // listeners
      this.button.addEventListener('click', this.bindedHandleCopy);
    }
    disconnectedCallback() {
      this.button.removeEventListener('click', this.bindedHandleCopy);
    }
    handleCopy(e) {
      e.preventDefault();

      // copy text
      const content = this.target.textContent.replace(/^\s+|\s+$/g, '');
      navigator.clipboard.writeText(content);

      // add confirmation
      const message = document.createElement('span');
      message.classList.add('is-confirmation');
      message.textContent = this.message;
      this.button.insertAdjacentElement('afterend', message);
    }
  }
  customElements.define('copy-list', CopyList);
});