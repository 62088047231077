document.addEventListener('DOMContentLoaded', function () {
  // -----------------------------------------------------------------
  // styled select with a floating label
  class MagicSelect extends HTMLElement {
    constructor() {
      super();
    }
    connectedCallback() {
      // elements
      this.selectElement = this.querySelector('select');

      // binding handler
      this.bindedHandleLoad = this.handleLoad.bind(this);
      this.bindedHandleChangeValue = this.handleChangeValue.bind(this);

      // on load
      this.bindedHandleLoad();

      // listeners
      this.selectElement.addEventListener('change', this.bindedHandleChangeValue);
    }
    disconnectedCallback() {
      this.selectElement.removeEventListener('change', this.bindedHandleChangeValue);
    }
    handleChangeValue(e) {
      const value = e.target.value;
      this.selectElement.setAttribute('data-value', value);
    }
    handleLoad() {
      const selected = this.selectElement.querySelector('option[selected]');
      if (selected) {
        this.selectElement.setAttribute('data-value', selected.value);
      }
    }
  }
  customElements.define('magic-select', MagicSelect);
});