import ScrollBooster from 'scrollbooster';
document.addEventListener('DOMContentLoaded', function () {
  // -----------------------------------------------------------------
  // date navigation controlling a swiper in  history page
  class DatesNav extends HTMLElement {
    constructor() {
      super();
    }
    connectedCallback() {
      // console.log('dates-nav is mounted')

      // elements
      this.linkElements = this.querySelectorAll('a');
      this.scrollElement = this.querySelector('.js-dates-nav__scroller');

      // communicating with slider
      this.targetSlider = this.getAttribute('data-target');
      this.sliderElement = document.querySelector(this.targetSlider);

      // binding handler
      this.bindedClick = this.click.bind(this);

      // on load
      this.addScrollBooster();

      // listeners
      this.addEventListener('click', this.bindedClick);
    }
    disconnectedCallback() {
      this.removeEventListener('click', this.bindedClick);
    }
    addScrollBooster() {
      this.scroller = new ScrollBooster({
        viewport: this.scrollElement,
        scrollMode: 'native',
        direction: 'horizontal'
      });
    }
    click(e) {
      const target = e.target.closest('a');
      const links = Array.from(this.linkElements);
      if (!target || !links.includes(target)) return;
      e.preventDefault();

      // triggers slider
      const index = links.indexOf(target);
      this.sliderElement?.navTo(index);
    }
    // triggered by push-slider.js
    onSlideChange(index) {
      this.linkElements.forEach((element, i) => {
        if (i === index) {
          element.setAttribute('aria-current', 'true');
        } else {
          element.removeAttribute('aria-current');
        }
      });
    }
  }
  customElements.define('dates-nav', DatesNav);
});