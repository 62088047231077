// Toggle burger button
document.addEventListener('DOMContentLoaded', function () {
  const burgerOn = document.getElementById('s-header__burger');
  const burgerOff = document.getElementById('s-header__close');
  const nav = document.getElementById('s-header-mobile');
  const logo = document.getElementById('s-header__logo');
  const body = document.querySelector('body');
  burgerOn.addEventListener('click', () => {
    burgerOn.classList.add('is-disabled');
    burgerOff.classList.add('is-active');
    nav.classList.add('is-active');
    logo.classList.add('is-active');
    body.classList.add('is-with-menu');
  });
  burgerOff.addEventListener('click', () => {
    burgerOn.classList.remove('is-disabled');
    burgerOff.classList.remove('is-active');
    nav.classList.remove('is-active');
    logo.classList.remove('is-active');
    body.classList.remove('is-with-menu');
  });
});